import React, { useRef, useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useNewOrder } from 'core';
const render = (status) => {
    return <h1>{status}</h1>;
};

const MyMap = ({ option, zoom = 3, style = "streetView" }) => {
    const ref = React.useRef(null);
    const [map, setMap] = React.useState();
    const { mapCenter, setMapCenter } = useNewOrder();

    useEffect(() => {
        if (ref.current && style === 'streetView') {
            new window.google.maps.StreetViewService()
                .getPanorama({ location: mapCenter, radius: 50 }, function(data, status) {
                    if (status === 'OK') {
                        setMap(new window.google.maps.StreetViewPanorama(ref.current, {
                            position: mapCenter,
                            pov: {
                                heading: 34,
                                pitch: 10,
                            },
                            ...option
                        }));
                    }
                    else {
                        const map = new window.google.maps.Map(ref.current, {
                            zoom: mapCenter !== { lat: 40.8974984, lng: -97.6722622 } ? 9 : 3,
                            center: mapCenter,
                            ...option
                        });
                        if (mapCenter !== { lat: 40.8974984, lng: -97.6722622 }) { // The marker, positioned at Uluru
                            const marker = new window.google.maps.Marker({
                                position: mapCenter,
                                map: map,
                            });
                        }
                        setMap(map);
                    }
                });
        }
        if (ref.current && style === 'normal') {
            const map = new window.google.maps.Map(ref.current, {
                zoom: 9,
                center: mapCenter,
                ...option
            });
            // The marker, positioned at Uluru
            const marker = new window.google.maps.Marker({
                position: mapCenter,
                map: map,
            });
            setMap(map)

        }
    }, [ref, mapCenter]);
    return <div ref={ref} style={{height:'30vh'}}/>
}

export default function MapComponent({ option, zoom, style }) {
    const { mapKey } = useNewOrder();
    return (
        <div style={{height:'35vh'}}>
        <MyMap option={option} zoom={zoom} style={style} />
        {/*mapKey && <Wrapper apiKey={mapKey} render={render}>
            <MyMap option={option} zoom={zoom} style={style} />
        </Wrapper>*/}
        </div>
    )
}
