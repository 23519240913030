import RequiresAuth from 'common/authentication/RequiresAuth';
import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';

import AdminChrome from 'pages/admin';

const DashboardSubPage = lazy(() => import('pages/admin/main'));
const NewDocumentSubPage = lazy(() => import('pages/admin/newdocument'));
const VendorContactsSubPage = lazy(() => import('pages/admin/vendors'));
const AutoPlacement = lazy(() => import('pages/admin/autoplacement'));
const SettingsSubPage       = lazy(() => import('pages/admin/settings'));
const OrderDetailsSubPage   = lazy(() => import('pages/admin/order/details'));
const PreliminaryReview     = lazy(() => import('pages/admin/preliminaryReview'));

export default function adminRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<RequiresAuth requires="ADMIN"><AdminChrome /></RequiresAuth>}>
          <Route index element={<DashboardSubPage />} />
          <Route path="new/:type" element={<NewDocumentSubPage />} />
          <Route path="vendors" element={<VendorContactsSubPage />} />
          <Route path="autoplacement" element={<AutoPlacement />} />
          <Route path="settings" element={<SettingsSubPage />} />
          <Route path="settings/reportcenter" element={<SettingsSubPage />} />
          <Route path="order/:orderid" element={<OrderDetailsSubPage />} />
          <Route path="preliminary" element = {<PreliminaryReview/>}/>
        </Route>
      </Routes>
    </>
  );
}
