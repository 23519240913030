import { useEffect, Fragment } from 'react';
import {
  Grid,
  Typography,
  Box,
  Card,
  CircularProgress,
  Radio,
}
from "@mui/material";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useNewOrder } from 'core';
import "./style.scss";
const productCard = (product, newOrderDetail, setNewOrderDetail) => {

  const handleProductSelect = (id, amount) => {
    setNewOrderDetail({ ...newOrderDetail, order_type_id: id, invoiceamount: amount })
  }

  return (
    <Card onClick={(e) => handleProductSelect(product.id, product.amount)} sx={{ px: 1, py: 1, mx: 1, mb: 1, cursor: 'pointer' }} className='d-flex' variant="outlined" key={product.id} >
      <Radio
        checked={newOrderDetail.order_type_id ? newOrderDetail.order_type_id == product.id : false}
        value={product.id}
        name={product.name} />
      <div className='d-flex w-100'>
        <div>
          {product.isSingleDocument === 1 ? <TextSnippetOutlinedIcon sx={{ fontSize: 60, fontWeight: 300, color: '#3d59d5' }} /> :
            <Inventory2OutlinedIcon sx={{ fontSize: 60, fontWeight: 300, color: '#3d59d5' }} />}
        </div>
        <div className='card-content'>
          <div>
            <span className='product-card-title'>{product.name}</span><br />
            <span>{product.descrip}
            </span>
          </div>
          <div>
            <span className='product-card-title'>${product.amount}</span>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default function Product() {


  const { getProductList, productLists, productLoader, newOrderDetail, setNewOrderDetail } = useNewOrder();

  useEffect(() => {
    getProductList();
  }, []);

  return (
    <Fragment>
      {
        productLoader ? <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', mx: 'auto' }}>
          <CircularProgress sx={{ mx: 'auto' }} />
        </Box> : <Grid sx={{ mt: 1, px: 2, fontSize: 12, display: 'block' }} container>
          <Typography variant="h6" component="h6" sx={{ fontWeight: 900 }}>
            Select Product
          </Typography>
          <div className="productList">
            {productLists && productLists.map((product) => productCard(product,newOrderDetail, setNewOrderDetail))}
          </div>
        </Grid>
      }
    </Fragment>
  );
}
