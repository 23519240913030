import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { formatGoogleAddress } from 'utils/address-formater';
import { useNewOrder } from 'core';
import SmartySDK from "smartystreets-javascript-sdk";
import SearchIcon from '@mui/icons-material/Search';
const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usAutocompletePro.Lookup;
// US Autocomplete Pro only supports using Embedded Keys
let key = "13201584230543569";
const credentials = new SmartyCore.SharedCredentials(key);

export default function AddressAutocomplete({ selectedAddress, participantAddress = false, addressField, participantIndex }) {
    const [addressOptions, setAddressOptions] = useState([]);
    const { verifiyAddress, setNewOrderDetail, newOrderDetail } = useNewOrder();
    const getPossiblePlaces = (value) => {
        new window.google.maps.places.AutocompleteService().getPlacePredictions({
                input: value,
                componentRestrictions: { country: ['us'] },
                types: ['address']
            },
            (displaySuggestions) => {

                if (displaySuggestions)
                    setAddressOptions(displaySuggestions);
            })
    }
    const getAddress = (place_id) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId: place_id }, function(results, status) {
            if (status === "OK") {
                if (results) {
                    let addressResponse = formatGoogleAddress({ ...results[0] });
                    verifiyAddress({
                        ...addressResponse,
                        latlng: {
                            "latitude": results[0].geometry.location.lat(),
                            "longitude": results[0].geometry.location.lng()
                        }
                    }, participantAddress, participantIndex)
                }
            }
        })
    }
    useEffect(() => {}, [newOrderDetail, addressField]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (!participantAddress) {
            setNewOrderDetail({
                ...newOrderDetail,
                [name]: value,
            });
        }
        else {
            const participantDetail = newOrderDetail.participants;
            participantDetail[participantIndex][name] = value;
        }
    };

    const addressRenderOption = (params) => {
        params.inputProps.value = participantAddress ? newOrderDetail.participants[participantIndex][addressField] : newOrderDetail[addressField];
        return <TextField autoComplete='off' {...params} name={addressField}  onChange={handleInputChange}  label="Street Address"  />
    }

    return (
        <Autocomplete
                id="address"
                freeSolo
                onInputChange={(event) => {
                getPossiblePlaces(event.target.value);
                }}
                options={[...addressOptions]}
                name={addressField}
                size="small"
                getOptionLabel={(option) => option.description}
                onChange={(e,value) => {
                    if (value && value.place_id) {
                        getAddress(value.place_id);
                    }
                }}
                fullWidth
                variant="outlined"
                renderInput={(params) => addressRenderOption(params)}
/>
    )
}

// export default function AddressAutocomplete() {
//     // The appropriate license values to be used for your subscriptions
//     // can be found on the Subscription page of the account dashboard.
//     // https://www.smarty.com/docs/cloud/licensing
//     let clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(["us-autocomplete-pro-cloud"]);
//     // .withBaseUrl("");
//     let client = clientBuilder.buildUsAutocompleteProClient();

//     // *** Using Filter and Prefer ***
//     let lookup = new Lookup("4770 Lincoln");

//     lookup.maxResults = 10;


//     async function handleRequest(lookup, lookupType) {
//         try {
//             const results = await client.send(lookup);
//             logSuggestions(results, lookupType);
//         }
//         catch (err) {
//             console.log(err)
//         }
//     }

//     handleRequest(lookup, "Using Filter and Prefer");

//     function logSuggestions(response, message) {
//         console.log(message);
//         console.log(response.result);
//         console.log("*********************");
//     }

//     return null;
// }
