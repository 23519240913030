import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,

}
from "@mui/material";
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MyMap from 'components/Map';
import { useNewOrder } from 'core';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import AddressAutocomplete from 'components/address-autocomplete';
import './style.scss';
export default function ClientOrder() {


  const {
    setNewOrderDetail,
    newOrderDetail,
    clientLists,
    getUserList,
    userLists,
    selectedAddress,
    selectedClient,
    setSelectedClient,
    entityState,
    getEntityState,
    zipcodeLookup,
    mapKey
  } = useNewOrder();

  useEffect(() => {
    getEntityState();
  }, [])

  const clientChange = (event) => {
    setSelectedClient(event.target.value);
    getUserList(event.target.value);
  };

  const userChange = (event) => {
    setNewOrderDetail({ ...newOrderDetail, orderbyid: event.target.value })
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewOrderDetail({
      ...newOrderDetail,
      [name]: value,
    });
    if (name.includes("zip") && value.length === 5) {
      zipcodeLookup(value);
    }
  };

  return (
    <Grid sx={{ mt: 1, px: 2, fontSize: 12 }} container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" component="h6" sx={{ fontWeight: 900 }}>
          Client & Order Information
        </Typography>
        Please provide the client account and user you would like to place this order for.
        <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="client-label">Select Client</InputLabel>
              <Select
                labelId="client-label"
                id="client"
                name="client"
                label="Select Client"
                onChange={clientChange}
                defaultValue=""
              >
                {clientLists &&
                  clientLists.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.client_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label" disabled={!selectedClient}>
                Select User
              </InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="user"
                label="Select User"
                disabled={!selectedClient}
                onChange={userChange}
                value={newOrderDetail?.orderbyid}
                defaultValue=""
              >
                {userLists &&
                  userLists.map((user) => (
                    <MenuItem key={user.userid} value={user.userid}>
                      {`${user.firstname} ${user.lastname}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
          <Grid item xs={12} sm={12} md={6}>
            <TextField autoComplete='off'
              label="Escrow Number"
              name="escrow_num"
              size="small"
              value={newOrderDetail?.escrow_num ? newOrderDetail?.escrow_num : ''}
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField autoComplete='off'
              label="Loan Reference Number"
              name="loanreference"
              size="small"
              value={newOrderDetail?.loanreference ? newOrderDetail?.loanreference : ''}
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Anticipated Close Date"
                size="small"
                value={newOrderDetail?.anticipated_close_date ? newOrderDetail?.anticipated_close_date : null}
                onChange={(newValue) => {
                  setNewOrderDetail({ ...newOrderDetail, anticipated_close_date: moment(newValue).format('YYYY-MM-DD h:mm:ss') });
                }}
                renderInput={(params) => <TextField autoComplete='off' size="small" fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Wrapper apiKey={mapKey} libraries={["places"]}>
      <Grid item xs={12} sm={12} md={7} lg={7} sx={{ mt: 2 }} >
        <Typography variant="h6" component="h6" sx={{ fontWeight: 900 }}>
          Property Address
        </Typography>
        Please provide the address of property that is part of transaction.
        <Grid container spacing={1} rowSpacing={1}>
          <Grid item xs={12} sm={12} md={12} sx={{ mt: 0 }}>
            <RadioGroup row size="small" sx={{ mt: 0 }}
              aria-labelledby="demo-row-radio-buttons-group-label"
              onChange={handleInputChange}
              value={newOrderDetail?.property_address_type}
              name="property_address_type">
              <FormControlLabel sx={{ fontSize: '14PX' }} value="single" control={<Radio />} label="Single Address" />
              <FormControlLabel sx={{ fontSize: '14px' }} value="apn" control={<Radio />} label="APN only" />
            </RadioGroup>
          </Grid>
        </Grid>
        <>
          {newOrderDetail?.property_address_type === 'single' ?
            <>
              <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
                <Grid item xs={12} sm={12} md={12} >
                  <AddressAutocomplete addressField="propertyaddress" selectedAddress={selectedAddress} />
                </Grid>
              </Grid>
              <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
                <Grid item xs={6} sm={6} md={6} >
                  <TextField autoComplete='off'
                    label="Address 2/Suite/Apt"
                    name="propertyaddress2"
                    size="small"
                    value={newOrderDetail?.propertyaddress2 || ''}
                    variant="outlined"
                    onChange={handleInputChange}
                    sx={{ mr: 1 }}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} >
                  <TextField autoComplete='off'
                    label="City"
                    name="propertycity"
                    size="small"
                    value={newOrderDetail?.propertycity || ''}
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
                <Grid item xs={6} sm={6} md={6} >
                  <FormControl size="small" fullWidth>
                    <InputLabel id="entity-state-label">State</InputLabel>
                    <Select
                      labelId="entity-state-label"
                      id="entity-state-select"
                      name="propertystate"
                      label="Entity State"
                      value={newOrderDetail?.propertystate || ''}
                      sx={{ mr: 1 }}
                      onChange={handleInputChange}
                    >
                      {entityState && entityState.map((state) => <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={6} >
                  <TextField autoComplete='off'
                    label="ZIP"
                    name="propertyzipcode"
                    size="small"
                    value={newOrderDetail?.propertyzipcode || ''}
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </>
            :
            <div className="d-flex"><TextField autoComplete='off'
              label="APN No."
              name="apn"
              size="small"
              value={newOrderDetail?.apn}
              variant="outlined"
              onChange={handleInputChange}
              sx={{ mr: 1 }}
            />
              <TextField autoComplete='off'
                label="ZIP"
                name="propertyzipcode"
                size="small"
                value={newOrderDetail?.propertyzipcode}
                variant="outlined"
                onChange={handleInputChange}
              />
            </div>
          }
        </>
      </Grid >
      <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: 4, ml: 2 }} >
        <MyMap
          zoom={selectedAddress ? 2 : 1}
          style={newOrderDetail?.property_address_type === 'single' ? 'streetView' : 'normal'}
          option={{
            addressControlOptions: {
              position: 'none',
            },
            linksControl: false,
            panControl: false,
            enableCloseButton: false,
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            rotateControl: false,
            mapTypeControl: false,
          }} />
      </Grid>
      </Wrapper>
    </Grid >

  )
}
