import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';

import Loading from 'pages/loading';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DropdownMenu from 'common/ui/DropdownMenu';
import { useVendor } from "core";

// import OrderDetailsModal from './OrderDetailsModal';

import useToggledState from '@twipped/hooks/useToggledState';
import useAuthentication from 'common/authentication';
import { PipelineServerProvider } from 'common/pipelines/usePipelineServer';
import useCompany from 'common/company';
import Column from 'common/ui/Column';
import Row from 'common/ui/Row';
import GlobalSearch from 'common/ui/GlobalSearch';
import SidebarContents from './sidebar';

import VendorProfile from "./vendor/vendorProfile";


const DRAWER_WIDTH = 240;

export default function AdminPages () {
  const { setVendorProfile, getVendorDetail } = useVendor();

  const {
    state: isDrawerOpen,
    on: openDrawer,
    off: closeDrawer,
    toggle: toggleDrawer,
  } = useToggledState();
  const { logout } = useAuthentication();
  const { companyid } = useCompany();
  console.log("reflects changes")
  return (
    <PipelineServerProvider companyid={companyid}>
      <Row align="stretch" fill sx={{ minHeight: '100vh' }}>
        <Paper
          elevation={4}
          square
          sx={{
            flexBasis: DRAWER_WIDTH,
            minWidth: DRAWER_WIDTH,
            display: { xs: 'none', md: 'block' },
          }}
        >
          <SidebarContents />
        </Paper>
        <Column fill>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{
                mr: { xs: 1, sm: 2 },
                display: { md: 'none' },
              }}
            >
              <MenuIcon />
            </IconButton>
            <GlobalSearch
              sx={{
                flexGrow: 2,
                maxWidth: { md: 500 },
              }}
              getVendorDetail={getVendorDetail}
            />
            <Box sx={{ flexGrow: { xs: 0, md: 1 } }} />
            <DropdownMenu
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              target={(
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  sx={{
                    ml: { xs: 1, sm: 2 },
                  }}
                >
                  <AccountCircle fontSize="inherit" />
                </IconButton>
              )}
            >
              <DropdownMenu.Item onClick={() => {setVendorProfile(true)}}>Profile</DropdownMenu.Item>
              <DropdownMenu.Item onClick={logout}>Logout</DropdownMenu.Item>
            </DropdownMenu>
            <VendorProfile />
          </Toolbar>
          <Column grow sx={{ pl: 3, pr: 3, pt: 1 }}>
            <Suspense fallback={<Loading />}><Outlet /></Suspense>
          </Column>
        </Column>

        <SwipeableDrawer
          container={document.body}
          variant="temporary"
          open={isDrawerOpen}
          onOpen={openDrawer}
          onClose={closeDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
        >
          <SidebarContents />
        </SwipeableDrawer>
         {/*<VendorProfile />
        <OrderDetailsModal /> */}
      </Row>
    </PipelineServerProvider>
  );
}

