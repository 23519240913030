import React, { useRef, useState, useEffect, Fragment } from 'react';
import CompanyLogo from 'common/ui/CompanyLogo';
import CompanyAddress from 'common/ui/CompanyAddress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepConnector from '@mui/material/StepConnector';
import { styled } from '@mui/material';
import { withStyles } from '@mui/styles';
import ClientOrder from './clientOrder';
import Product from './product';
import Participants from './participants';
import OrderConfirm from './orderConfirm';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ReactToPrint from "react-to-print";
import { useNewOrder } from 'core';
import './style.scss';

const steps = [
  'CLIENT & PROPERTY INFORMATION',
  'PRODUCT SELECTION',
  'PARTICIPANTS & ENTITIES',
  'ORDER CONFIRMATION'
];

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
};


export default function newOrder({ show, setShow }) {

  const handleOpen = () => setShow(true);
  const [cancelModal, setCancelModal] = useState(false);
  const btnRef = useRef();
  const printCompRef = useRef();

  useEffect(() => {}, [activeStep]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (<ClientOrder />);
      case 1:
        return (<Product />);
      case 2:
        return (<Participants btnRef={btnRef} />);
      case 3:
        return (<OrderConfirm />);
      default:
        return 'Unknown step';
    }
  };
  const {
    newOrderDetail,
    nextButton,
    activeStep,
    setActiveStep,
    resetFormValue,
    orderSteps,
    setOrderSteps,
    addNewOrder,
    setShowOrderModal
  } = useNewOrder();

  const handleNext = () => {
    if (newOrderDetail.order_type_id === 254 && activeStep === 1) {
      addNewOrder();
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (activeStep === 2) {
        btnRef.current.click();
      }
    }
    setOrderSteps([...orderSteps, activeStep]);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setShow(false);
    resetFormValue();
    handleReset();
  };

  const handleBack = () => {
    if (newOrderDetail.order_type_id === 254 && activeStep === 3) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const NextButton = styled(Button)`
display: flex;
justify-content: flex-start;
margin: 12px;
margin-bottom: 16px;
`;

  const LogoHeader = () => {
    return (
      <Box sx={{ display: 'none' }}>
        <div className="print-section" ref={printCompRef}>
          <CompanyLogo />
          <OrderConfirm />
          <CompanyAddress />
        </div>
      </Box>
    )
  }

  return (
    <Fragment>
      <Modal
        open={show}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Place Order"}>
        <Box sx={{ ...ModalStyle, width: '750px' }} >
          <div className='order-modal-header'>
            <Typography variant="h4" component="h4">
              Place Order
            </Typography>
          </div>
          <div className='order-modal-content'>
            <Stepper sx={{ mt: 3, p: 2 }} activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel sx={{ cursor: 'pointer' }} onClick={() => orderSteps.includes(index) && setActiveStep(index)} StepIconProps={{ icon: null }}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  {activeStep !== steps.length - 1 && <CancelModal setCancelModal={setCancelModal} cancelModal={cancelModal} handleClose={handleClose} resetFormValue={resetFormValue} />}
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep !== steps.length - 1 ? <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    startIcon={<ArrowLeftIcon sx={{ fontSize: 'large' }} />}
                  >
                    BACK
                  </Button> :
                    <ReactToPrint
                      trigger={() => <Button
                        color="inherit"
                        sx={{ mr: 1 }}
                      >
                        PRINT
                      </Button>}
                      content={() => printCompRef.current}
                    />}
                  <NextButton disabled={nextButton} onClick={activeStep === steps.length - 1 ? handleClose : handleNext} variant="contained" endIcon={<ArrowRightIcon sx={{ fontSize: 'large' }} />}> {activeStep === steps.length - 1 ? 'FINISH' : 'NEXT'}</NextButton>
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
      </Modal>
      <LogoHeader/>
    </Fragment>
  );
}

function CancelModal({ setCancelModal, cancelModal, handleClose, resetFormValue }) {

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={() => setCancelModal(true)}
        sx={{ mr: 1, color: '#3d59d5' }}
      >
        CANCEL
      </Button>
      <Modal
        hideBackdrop
        open={cancelModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Cancel Order"}>
        <Box sx={{ ...ModalStyle }} >
          <div className='order-modal-header'>
            <Typography variant="h4" component="h4">
              Cancel Order
            </Typography>
          </div>
          <Typography variant="h6" component="h6" sx={{ px: 2, py: 1 }}>
            Are you sure you want to cancel ?
          </Typography>
          <div className="modal-bottom">
            <Button

              onClick={() => { handleClose(); setCancelModal(false); resetFormValue(); }}
              sx={{ mr: 1, color: '#3d59d5' }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={() => setCancelModal(false)}
              sx={{ mr: 1, color: '#fff' }}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
}
