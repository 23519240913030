import { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  styled,
  CircularProgress,
  Divider
}
from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { css } from '@mui/system';
import Order from '../../../common/models/order';
import { useNewOrder } from 'core';
import moment from 'moment';
const TableContainerStyled = styled(TableContainer)(css`
  .MuiTableCell-head .MuiSvgIcon-root {
    color: #5a5a5b
  }
  
`);

const TableBodyStyled = styled(TableBody)(css`
  
`);

const TableCellStyled = styled(TableCell)(css`
  border: 0px !important;
  padding: 6px 2px;
  font-size: 14px;

  .MuiStep-root {
    margin-top: 16px;
  }
  .MuiStepLabel-label {
    font-size: 10px;
    padding-top: 4px;
    margin-top: 4px !important;
  }
`);

export default function OrderConfirm() {

  const [data, setData] = useState({ parts: [] });
  const [loader, setLoader] = useState(true);
  const {
    addedOrderId
  } = useNewOrder();

  useEffect(async () => {
    if (data.parts?.length === 0 && addedOrderId > 0) {
      const order = (new Order);
      const result = await order.getParts(addedOrderId);
      setData(result);
    }
    if (addedOrderId !== undefined)
      setLoader(false);
  }, [data.parts, addedOrderId]);


  return (
    <>
      {loader ?
        <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', mx: 'auto' }}>
          <CircularProgress sx={{ mx: 'auto' }} />
        </Box> :
          addedOrderId >0 ?
            <Box  sx={{ px: '16px',mt:3 }} >
              <Typography variant="h6" component="h6" sx={{ fontWeight: 700 }}>
                Thank You!
                Your order Successfully created.
              </Typography>
              <TableContainerStyled>
                <Table sx={{ minWidth: 650 }} aria-label="">
                  <TableHead>
                    <TableRow className="order-summary-header">
                      <TableCell>ORDER DATE</TableCell>
                      <TableCell>ORDER ID</TableCell>
                      <TableCell>ORDER BY</TableCell>
                      <TableCell>ORDER TYPE</TableCell>
                      <TableCell sx={{width:'110px'}} >AMOUNT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBodyStyled>
                  {data?.parts?.map((row) => (
            <TableRow
              key={row.partid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCellStyled component="th" scope="row">
                {moment(row.document_ready).format('MM/DD/YYYY')}
              </TableCellStyled>
              <TableCellStyled component="th" scope="row">
            {row.orderid}
              </TableCellStyled>
              <TableCellStyled component="th" scope="row">
            {row.orderby}
              </TableCellStyled>
              <TableCellStyled component="th" scope="row">
                {row.part_title}
              </TableCellStyled>
              <TableCellStyled component="th" scope="row">
                {row.vendorfee}
              </TableCellStyled>
              </TableRow>))}
                  </TableBodyStyled>
                </Table>
              </TableContainerStyled>
              <Divider sx={{ mt: 5 }} />
              <Box sx={{display:'flex'}}>
                <Box sx={{ml:'auto',width:'50px'}}><b>Total</b></Box>
                <Box sx={{width:'110px'}}>{data?.parts?.reduce((a, b)=> a + b.vendorfee, 0)}</Box>
              </Box>
            </Box>
            : <Box sx={{ px: '16px',mt:3 }}>
              <Typography variant="h6" component="h6" sx={{ fontWeight: 700 }}>
                Please try again, Your order isn't created suceessfuly.
              </Typography>
            </Box>
      }
    </>
  )
}
