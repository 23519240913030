import { useState, useEffect } from 'react';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';
import useLocalStorage, { useSessionStorage } from '@twipped/hooks/useLocalStorage';
import PropTypes from 'prop-types';
import { css } from "@mui/system";
import { styled } from '@mui/material/styles';

import useCompany from 'common/company';
import CompanyLogo from 'common/ui/CompanyLogo';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';
import GradingIcon from '@mui/icons-material/Grading';
import Order from "common/models/order";


import { useNewOrder } from 'core';
import NewOrder from './newOrder';
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

const SidebarContainer = styled.div`
  position: sticky;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  .MuiListItemText-primary {
    font-size:0.9rem;
  }
`;

const SidebarList = styled.div`
  .MuiListItemIcon-root {
    color: #005fd4;
  }
`;

const SidebarListBottom = styled(SidebarList)`
  position: absolute;
  bottom: 1em;
  left: 0;
  right: 0;
`;

const NewDocButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  margin: 12px;
  margin-bottom: 16px;
`;

function SidebarItem ({ to, caption, icon, target, clicked, count}) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  if (clicked)
    return (
      <ListItem button selected={!!match} onClick={clicked}>
        <ListItemIcon sx={{ minWidth: 36 }}>{icon}</ListItemIcon>
        <ListItemText primary={caption} />
      </ListItem>
    );
  return (
    <ListItem button component={Link} to={to} selected={!!match} target={target} onClick={clicked}>
      <ListItemIcon sx={{ minWidth: 36 }}>{icon}</ListItemIcon>
      <ListItemText primary={caption} />
      {count >0 && <Chip size="small" label={count} />}
    </ListItem>
  );
}
SidebarItem.propTypes = {
  to: PropTypes.string,
  caption: PropTypes.string,
  icon: PropTypes.element,
  target: PropTypes.string,
};

export default function SidebarContents() {
  const api = (new Order);
  const company = useCompany();

  const [user, setUser] = useSessionStorage('user');
  const [count, setCount] = useState(0);

  const { showOrderModal, setShowOrderModal } = useNewOrder();

  useEffect(async () => {
    try {
      let result = await api.PreliminaryOrderCount(user.userid);
      if (result.status === "success") {
        setCount(result.count);
      }
      setInterval(async () => {
        let result = await api.PreliminaryOrderCount();
        if (result.status === "success") {
          setCount(result.count);
        }
      }, 30000);
    }
    catch (e) {
      console.log(e);
    }
  }, []);
  
  return (
    <> <SidebarContainer>
      <Toolbar>
        <CompanyLogo />
      </Toolbar>
      <NewDocButton onClick={()=>setShowOrderModal(true)} variant="contained" startIcon={<PostAddOutlinedIcon />}>New Order</NewDocButton>
      <SidebarList>
        <SidebarItem to="/admin/"          icon={<ClearAllOutlinedIcon />}    caption="Pipelines" />
         {/*{user.show_assignment==='Y' && */}
         <SidebarItem to="/admin/preliminary" count={count}   icon={<GradingIcon />} caption="Preliminary Review" />
        <SidebarItem to="/admin/vendors"   icon={<ContactPageOutlinedIcon />} caption="Vendor Contacts" />
      </SidebarList>
      <SidebarListBottom>
        <SidebarItem to="/admin/settings/reportcenter" icon={<SummarizeOutlinedIcon />} caption="Report Center" />
        <SidebarItem to="/admin/autoplacement" icon={<GpsFixedOutlinedIcon />} caption="Auto Placement" />
        <SidebarItem to="/admin/settings"  icon={<SettingsOutlinedIcon />}    caption="Settings" target="_blank" />
        <Typography variant="caption" sx={{ pl: 2, pr: 1 }}>&copy; {company.name}, {(new Date).getFullYear()}</Typography>
      </SidebarListBottom>
    </SidebarContainer>

    {showOrderModal && <NewOrder show={showOrderModal} setShow={setShowOrderModal}/>}
    </>
  );


}
