import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Switch,
  FormControl,
  Divider
}
from "@mui/material";
import { IMaskInput } from "react-imask";
import { useNewOrder } from 'core';
import PropTypes from "prop-types";
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddressAutocomplete from 'components/address-autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SSNMaskCustom = React.forwardRef(function SSNMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#00-00-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

SSNMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const EINMaskCustom = React.forwardRef(function EINMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#0-0000000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

EINMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default function Participants({ btnRef }) {
  const [participantType, setParticipantType] = useState("INDIVIDUAL");
  // const [participants, setParticipants] = useState([{}]);

  const {
    participantLists,
    newOrderDetail,
    setNewOrderDetail,
    entityType,
    entityState,
    getEntityType,
    getEntityState,
    getParticipantLists,
    zipcodeLookup,
    selectedAddress,
    addNewOrder
  } = useNewOrder();

  useEffect(() => {
    getParticipantLists();
    getEntityType();
    getEntityState();
  }, []);

  useEffect(() => {
    setNewOrderDetail({ ...newOrderDetail, participants_type: participantType })
  }, [participantType]);

  const normalizeValue = (value) => {
    return value || "";
  };

  const handleParticipants = async (event, index) => {
    console.log(event.target, index);
    const { name, value } = event.target;
    const participantDetail = newOrderDetail.participants;
    participantDetail[index][name] = value;
    if (name === 'contact_same_as_borrower')
      setNewOrderDetail({ ...newOrderDetail, participants: participantDetail });
    if (name.includes("zip") && value.length === 5) {
      zipcodeLookup(value,index);
    }
  }


  const IndividualForm = ({ count, participant }) => {
    return (<Box className={`${count%2!==0? 'bg-grey':''}`}>
      {newOrderDetail.participants.length > 1 &&
        <Box>
    <CancelIcon sx={{float:'right',cursor:'pointer'}} onClick={()=>{newOrderDetail.participants.splice(count,1);setNewOrderDetail({...newOrderDetail,participants:newOrderDetail.participants})}}/>
    </Box>
      }
      <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
        <Grid item xs={12} sm={12} md={6}>
          <TextField autoComplete='off'
            label="Participant First Name "
            name="participants_firstname"
            size="small"
            value={participant.participants_firstname}
            fullWidth
            variant="outlined"
            onChange={(e) => handleParticipants(e, count)}
            required={participantType === 'INDIVIDUAL' && newOrderDetail.order_type_id === 253}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField autoComplete='off'
            label="Participant Last Name "
            name="participants_lastname"
            size="small"
            value={participant.participants_lastname}
            fullWidth
            variant="outlined"
            onChange={(e) => handleParticipants(e, count)}
            required={participantType === 'INDIVIDUAL' && newOrderDetail.order_type_id === 253}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
        <Grid item xs={12} sm={12} md={6}>
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="participant-role-label">Participant Role</InputLabel>
            <Select
              labelId="participant-role-label"
              id="participant-role-select"
              name="participants_role"
              label="Participant Role"
              onChange={(e) => handleParticipants(e, count)}
              required={participantType === 'INDIVIDUAL' && newOrderDetail.order_type_id === 253}
              defaultValue=""
              value={participant.participants_role}
            >
              {participantLists && participantLists.map((participant) =>
                <MenuItem key={participant.id} value={participant.id}>
                  {participant.descrip}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField autoComplete='off'
            fullWidth
            id="w9-tax_ssn"
            name="participants_ssn"
            value={participant.participants_ssn||''}
            label="Participant SSN#"
            variant="outlined"
            onChange={(e) => handleParticipants(e,count)}
            onBlur={(event)=>{newOrderDetail.participants[count].participants_ssn=event.target.value;setNewOrderDetail({...newOrderDetail})}}
            required={participantType === 'INDIVIDUAL' && newOrderDetail.order_type_id === 253}
            size="small"
            InputProps = {
              {
                inputComponent: SSNMaskCustom,
              }
            }
          />
        </Grid>
      </Grid><Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
        <Grid item xs={12} sm={12} md={6}>
          <TextField autoComplete='off'
            label="Cell Phone "
            name="contact_phone"
            size="small"
            fullWidth
            variant="outlined"
            value={participant.contact_phone||''}
            onChange={(e) => handleParticipants(e,count)}
            onBlur={(event)=>{newOrderDetail.participants[count].contact_phone=event.target.value;setNewOrderDetail({...newOrderDetail})}}
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            required={participantType === 'INDIVIDUAL' && newOrderDetail.order_type_id === 253}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField autoComplete='off'
            type="email"
            label="eMail "
            name="contact_email"
            size="small"
            value={participant.contact_email}
            fullWidth
            variant="outlined"
            onChange={(e) => handleParticipants(e, count)}
            required={participantType === 'INDIVIDUAL' && newOrderDetail.order_type_id === 253}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 1 }} />
      <AddressComponent count={count} participant={participant}/>
    </Box>)
  }

  const EntityForm = ({ count, participant }) => {
    return (<Box className={`${count%2!==0? 'bg-grey':''}`}>
    {newOrderDetail.participants.length > 1 &&
        <Box>
    <CancelIcon sx={{float:'right'}} onClick={()=>{newOrderDetail.participants.splice(count,1);setNewOrderDetail({...newOrderDetail,participants:newOrderDetail.participants})}}/>
    </Box>
      }
      <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
        <Grid item xs={12} sm={12} md={6}>
          <TextField autoComplete='off'
            label="Legal Business Name "
            name="entity_businessname"
            size="small"
            value={participant.entity_businessname}
            fullWidth
            variant="outlined"
            onChange={(e) => handleParticipants(e, count)}
            required={participantType === 'ENTITY' && newOrderDetail.order_type_id === 253}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="entity-type-label">Entity Type</InputLabel>
            <Select
              labelId="entity-type-label"
              id="entity-select"
              name="entity_type"
              label="Entity Type"
              value={participant.entity_type}
              onChange={(e) => handleParticipants(e, count)}
               required={participantType === 'ENTITY' && newOrderDetail.order_type_id === 253}
              defaultValue=""
            >
              {entityType && entityType.map((type) => <MenuItem key={type} value={type}>
                {type}
              </MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="entity-state-label">Entity State</InputLabel>
            <Select
              labelId="entity-state-label"
              id="entity-state-select"
              name="entity_state"
              label="Entity State"
              onChange={(e) => handleParticipants(e, count)}
              required={participantType === 'ENTITY' && newOrderDetail.order_type_id === 253}
              value={participant.entity_state}
              defaultValue=""
            >
              {entityState && entityState.map((state) => <MenuItem key={state} value={state}>
                {state}
              </MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
        <Grid item xs={12} sm={12} md={6}>
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="participant-role-label">Participant Role</InputLabel>
            <Select
              labelId="participant-role-label"
              id="participant-role-select"
              name="participants_role"
              label="Participant Role"
              onChange={(e) => handleParticipants(e, count)}
              value={participant.participants_role}
               required={participantType === 'ENTITY' && newOrderDetail.order_type_id === 253}
              defaultValue=""
            >
              {participantLists && participantLists.map((participant) =>
                <MenuItem key={participant.id} value={participant.id}>
                  {participant.descrip}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField autoComplete='off'
            fullWidth
            id="w9-tax_ein"
            name="entity_ein"
            value={normalizeValue(participant.entity_ein)}
            label="Entity EIN # "
            variant="outlined"
            size="small"
            onChange={(event) => handleParticipants(event, count)}
            onBlur={(event)=>{newOrderDetail.participants[count].entity_ein=event.target.value;setNewOrderDetail({...newOrderDetail})}}
            required={participantType === 'ENTITY' && newOrderDetail.order_type_id === 253}
          InputProps={{
            inputComponent: EINMaskCustom,
          }}
          />
        </Grid>
      </Grid><Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
        <Grid item xs={12} sm={12} md={3}>
          <TextField autoComplete='off'
            label="Contact First Name "
            name="contact_firstname"
            size="small"
            value={participant.contact_firstname}
            fullWidth
            variant="outlined"
            onChange={(e) => handleParticipants(e, count)}
            required={participantType === 'ENTITY' && newOrderDetail.order_type_id === 253}
          />
        </Grid><Grid item xs={12} sm={12} md={3}>
          <TextField autoComplete='off'
            label="Contact Last Name "
            name="contact_lastname"
            size="small"
            value={participant.contact_lastname}
            fullWidth
            variant="outlined"
            onChange={(e) => handleParticipants(e, count)}
             required={participantType === 'ENTITY' && newOrderDetail.order_type_id === 253}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField autoComplete='off'
            label="Cell Phone "
            name="contact_phone"
            size="small"
            fullWidth
            variant="outlined"
            value={participant.contact_phone}
            onChange={(e) => handleParticipants(e, count)}
            onBlur={(event)=>{newOrderDetail.participants[count].contact_phone=event.target.value;setNewOrderDetail({...newOrderDetail})}}
            maxLength={10}
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            required={participantType === 'ENTITY' && newOrderDetail.order_type_id === 253}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField autoComplete='off'
            label="eMail "
            name="contact_email"
            size="small"
            type="email"
            value={participant.contact_email}
            fullWidth
            variant="outlined"
            onChange={(e) => handleParticipants(e, count)}
            required={participantType === 'ENTITY' && newOrderDetail.order_type_id === 253}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 1 }} />
      <AddressComponent count={count} participant={participant}/>
    </Box>)
  }

  const handleSubmit = (event) => {
    addNewOrder();
    event.preventDefault();
  }

  const AddressComponent = ({ count, participant }) => {
    console.log("participant-->", participant);
    return <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
    <Grid item xs={12} sm={12} md={5}>
        <div className="address-section">
          <Typography sx={{ fontSize: 10, fontWeight: 800}}>Current Address</Typography>
          <Switch
            size='small'
            checked={participant.contact_same_as_borrower}
            value={participant.contact_same_as_borrower}
            onChange={(event) => {participant.contact_same_as_borrower=event.target.checked;handleParticipants({target:{name:'contact_same_as_borrower',value:event.target.checked}},count) }}
          /> <Typography sx={{ fontSize: 10, fontWeight: 800, color: '#3d59d5' }}>SAME AS ORDER PROPERTY</Typography>
        </div>
        </Grid>
    <Grid item xs={12} sm={12} md={7}>
          {
            participant.contact_same_as_borrower ?
              <>{newOrderDetail?.property_address_type === 'single' ?
                <>
                  <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
                    <Grid item xs={12} sm={12} md={12} >
                      <TextField autoComplete='off'
                        label="Street Address"
                        name="propertyaddress"
                        size="small"
                        value={newOrderDetail?.propertyaddress || ''}
                        variant="outlined"
                        fullWidth
                        disabled
                        sx={{ my: 1 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
                    <Grid item xs={6} sm={6} md={6} >
                      <TextField autoComplete='off'
                        label="Address 2/Suite/Apt"
                        name="propertyaddress2"
                        size="small"
                        value={newOrderDetail?.propertyaddress2}
                        variant="outlined"
                        disabled
                        sx={{ mr: 1 }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} >
                      <TextField autoComplete='off'
                        label="City"
                        name="propertycity"
                        size="small"
                        value={newOrderDetail?.propertycity || ''}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
                    <Grid item xs={6} sm={6} md={6} >
                      <FormControl size="small" fullWidth>
                        <InputLabel id="entity-state-label">State</InputLabel>
                        <Select
                          labelId="entity-state-label"
                          id="entity-state-select"
                          name="propertystate"
                          label="Entity State"
                          disabled
                          value={newOrderDetail?.propertystate || ''}
                          sx={{ mr: 1 }}
                        >
                          {entityState && entityState.map((state) => <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} >
                      <TextField autoComplete='off'
                        label="ZIP"
                        name="propertyzipcode"
                        size="small"
                        disabled
                        value={newOrderDetail?.propertyzipcode || ''}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </>
                :
                <div className="d-flex"><TextField autoComplete='off'
                  label="APN No."
                  name="apn"
                  size="small"
                  value={newOrderDetail?.apn}
                  variant="outlined"
                  sx={{ mr: 1 }}
                  disabled
                />
                  <TextField autoComplete='off'
                    label="ZIP"
                    name="propertyzipcode"
                    size="small"
                    value={newOrderDetail?.propertyzipcode}
                    variant="outlined"
                    disabled
                  />
                </div>
              }</>
              :
              <>{newOrderDetail?.property_address_type === 'single' ?
                <>
                  <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
                    <Grid item xs={12} sm={12} md={12} >
                      <AddressAutocomplete addressField="participant_address" selectedAddress={selectedAddress} participantAddress={true} participantIndex={count} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
                    <Grid item xs={6} sm={6} md={6} >
                      <TextField autoComplete='off'
                        label="Address 2/Suite/Apt"
                        name="participant_address2"
                        size="small"
                          value={participant?.participant_address2 || ''}
                        variant="outlined"
                        onChange={(e) => handleParticipants(e, count)}
                        sx={{ mr: 1 }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} >
                      <TextField autoComplete='off'
                        label="City"
                        name="participant_city"
                        size="small"
                        value={participant?.participant_city}
                        variant="outlined"
                        onChange={(e) => handleParticipants(e, count)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
                    <Grid item xs={6} sm={6} md={6} >
                      <FormControl size="small" fullWidth>
                        <InputLabel id="entity-state-label">State</InputLabel>
                        <Select
                          labelId="entity-state-label"
                          id="entity-state-select"
                          name="participant_state"
                          label="Entity State"
                          value={participant?.participant_state}
                          sx={{ mr: 1 }}
                          onChange={(e) => handleParticipants(e, count)}
                        >
                          {entityState && entityState.map((state) => <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} >
                      <TextField autoComplete='off'
                        label="ZIP"
                        name="participant_zip"
                        size="small"
                        value={participant?.participant_zip}
                        variant="outlined"
                        onChange={(e) => handleParticipants(e, count)}
                      />
                    </Grid>
                  </Grid>
                </>
                :
                <div className="d-flex"><TextField autoComplete='off'
                  label="APN No."
                  name="participant_apn"
                  size="small"
                  value={participant?.participant_apn}
                  variant="outlined"
                  onChange={(e) => handleParticipants(e, count)}
                  sx={{ mr: 1 }}
                />
                  <TextField autoComplete='off'
                    label="ZIP"
                    name="participant_zip"
                    size="small"
                    value={participant?.participant_zip}
                    variant="outlined"
                    onChange={(e) => handleParticipants(e, count)}
                  />
                </div>
              }</>
          }
        </Grid>
        </Grid>
  }

  return (
    <Grid sx={{ mt: 1, px: 2, fontSize: 12 }} container>
      <Grid item xs={12} sm={12} md={12} lg={12}  >
        <Typography variant="h6" component="h6" sx={{ fontWeight: 900 }}>
          PARTICIPANTS & ENTITIES
        </Typography>
        Based on your selection, at least one transaction participant must be provided to place this order. Additional charges may apply  for adding additional participants and entities.
        <Grid container spacing={1} rowSpacing={1} sx={{ mt: 1 }} >
          <Grid item xs={12} sm={12} md={6} className='d-flex'>
            <Typography sx={{ fontSize: 12, fontWeight: 900, color: '#3d59d5' }}>INDIVIDUAL</Typography>
            <Switch
              size='small'
              checked={participantType === 'ENTITY' ? true : false}
              onChange={(event) => { setParticipantType(event.target.checked ? 'ENTITY' : 'INDIVIDUAL'); }}
            />
            <Typography sx={{ fontSize: 12, fontWeight: 900, color: '#3d59d5' }}>ENTITY</Typography>
          </Grid>
        </Grid>
        <div className='overflow-y-scroll'>
          <form onSubmit={handleSubmit}>
            {newOrderDetail.participants.map((participant,key)=><span key={`form${key}`}>{participantType === 'INDIVIDUAL'?<IndividualForm participant={participant} count={key}/>:<EntityForm participant={participant} count={key} />}</span>)}
            <input type="submit" className="d-none" ref={btnRef} />
          </form>
        </div>
      </Grid>
      
      <div className='add-participant-section' >
        <span onClick={() => {return setNewOrderDetail({...newOrderDetail,participants:[...newOrderDetail.participants,{contact_same_as_borrower: true}]})}}>
          <AddBoxIcon />
          <span className='add-participant-label'>Add New Participants</span>
        </span>
      </div>
    </Grid>);
}
