import { Box, Typography } from '@mui/material';
import useCompany from 'common/company';
export default function CompanyAddress() {

    const { address, legalName } = useCompany();

    return (
        <Box sx={{ml:1}}>
        <Typography variant="h5" component="h5" sx={{ fontWeight: 500 }}>
            {legalName}
        </Typography>
        <Typography variant="h6" component="h6" sx={{ fontWeight: 500 }}>
            {address.streetaddress}
        </Typography>
        <Typography variant="h6" component="h6" sx={{ fontWeight: 500 }}>
            {`${address.city}, ${address.state} ${address.zipcode}`}
        </Typography>
        <Typography variant="h6" component="h6" sx={{ fontWeight: 500 }}>
            {address.phone}
        </Typography>
    </Box>
    )
}
