import * as React from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Grid,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormGroup,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  Checkbox } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddBoxIcon from "@mui/icons-material/AddBox";
import Dialog from '@mui/material/Dialog';
import {css} from "@mui/system";
import { IMaskInput } from 'react-imask';
import { useVendor } from "core";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LicensureItme from "./licensureList";



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  }
}, css`
 .employees-dialog-header{
        background: ${theme.palette.primary.main};
        position: relative;
        color: #fff;
        padding: 20px 40px;
        font-weight: 400;
        font-size: 20px;
        .MuiButtonBase-root{
          color: #ffffff5e;
          top: 15px;
        }
    }

    .MuiInputLabel-formControl {
      background-color: #ffffff;
    }
    .MuiButtonBase-root{
      top: 0px;
    }
    .rightM {
      margin-right: 6px;
      color: #005fd4;
      padding: 8px 11px;
    }
`));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function profileData() {
  const { states, vendorProfile, setVendorProfile, profileData, setProfileData } = useVendor();
  const [open, setOpen] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('md');

  const handleClose = () => {
    setVendorProfile(false);
  };

  const normalizeValue = (value) => {
    return value || "";
  };

   const handleInputFiledChange = (event, index) => {
     const { name, value } = event.target;
      // setProfileData({
      //   ...profileData,
      //   [name]: value,
      // });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    // setProfileData({
    //   ...profileData,
    //   showPassword: !profileData.showPassword,
    // });
  };

  const handlePassword = (prop) => (event) => {
    setProfileData({ ...profileData, [prop]: event.target.value });
  };

  const onLicensureUpdate = (item, index) => {
    const updatedLicensure = profileData.certificates;
    profileData.certificates[index] = item;
    // setProfileData({
    //   ...profileData,
    //   certificates: updatedLicensure,
    // });
  }

  const saveEmployeeFun = () => {
    // const newItem = {
    //   ...profileData,
    //   // parent_user: formValue?.userid
    // }
    // setProfileData({
    //   newItem
    // });
    // saveEmployee(newItem);
    // setTabValue(0);
    // setOpen(false);
  }


  const addLicensure = () => {
    return profileData?.certificates?.map((item, index) => {
      return (<LicensureItme key={index} item={item} index={index} onUpdate={(item) => onLicensureUpdate(item, index)} />);
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="employees-dialog-header"
        maxWidth={maxWidth}
        open={vendorProfile}
      >
        <BootstrapDialogTitle className="employees-dialog-header" onClose={handleClose}>
          My Profile
        </BootstrapDialogTitle>
        <DialogContent dividers>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h5" component="h5" sx={{ marginBottom: '10px' }}>
                Contact Information
              </Typography>

              <Grid container spacing={2} rowSpacing={2}>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="FIRST NAME"
                    name="firstname"
                    value={normalizeValue(profileData?.firstname)}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="LAST NAME"
                    name="lastname"
                    value={normalizeValue(profileData?.lastname)}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="EMAIL"
                    name="email"
                    value={normalizeValue(profileData?.email)}
                    type="email"
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="Firm / Company Name"
                    name="company_name"
                    value={normalizeValue(profileData?.company_name)}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                        label="PHONE"
                        name="phone"
                        fullWidth
                        variant="outlined"
                        value={normalizeValue(profileData?.phone)}
                        onChange={handleInputFiledChange}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                </Grid>


                <Grid item xs={12} sm={12} md={4}>

                  <TextField
                    label="MOBILE"
                    name="mobile"
                    fullWidth
                    variant="outlined"
                    value={normalizeValue(profileData?.mobile)}
                    onChange={handleInputFiledChange}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />

                </Grid>

              </Grid>
            </Grid>
          </Grid>

          <Typography variant="h5" component="h5" sx={{ marginBottom: '10px', marginTop: '20px' }}>
              Licensure
            </Typography>

          {addLicensure()}

          <Grid container spacing={3} rowSpacing={2}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="text"
                endIcon={<AddBoxIcon />}
                size="large"
                color="primary"
                className="rightM"
                onClick={() => {
                  setProfileData({
                    ...profileData,
                    certificates: [...profileData?.certificates, {}],
                  });
                }}
              >
                Add LICENSE
              </Button>
            </Grid>
          </Grid>

      <Typography variant="h5" component="h5" sx={{ marginBottom: '10px' }}>
        Security
      </Typography>

      <Grid container spacing={3} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              PASSWORD
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={profileData.showPassword ? "text" : ""}
              value={profileData.password}
              onChange={handlePassword("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {profileData.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="PASSWORD"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              CONFIRM PASSWORD
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={profileData.showPassword ? "text" : ""}
              value={profileData.confirmPassword}
              onChange={handlePassword("confirm password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {profileData.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="CONFIRM PASSWORD"
            />
          </FormControl>
        </Grid>
        </Grid>
          <Button variant="outlined" size="large" autoFocus onClick={() => {}} style={{ margin: "10px 0", color: "#005fd4" }}>
            Cancel
          </Button>
          <Button variant="contained" size="large" autoFocus onClick={() => saveEmployeeFun()} style={{ margin: "10px 0", float: "right" }}>
            Update Profile
          </Button>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
