export const formatGoogleAddress = ({ name = "", formatted_address = "", address_components = [], addressLongName }) => {

    let address = {};
    if (address_components.length > 0) {
        let street_number = address_components.find(y => y.types.includes("street_number"));
        if (street_number) {
            address["street_number"] = (addressLongName ? street_number.long_name : street_number.short_name || "");
            address['full_address'] = `${address["street_number"]}`;
        }

        let route = address_components.find(y => y.types.includes("route"));
        if (route) {
            address["route"] = (addressLongName ? route.long_name : route.short_name || "");
            address['full_address'] = `${address['full_address']}, ${address["route"]}`;
        }

        let neighborhood = address_components.find(y => y.types.includes("neighborhood"));
        if (neighborhood) {
            address["neighborhood"] = (addressLongName ? neighborhood.long_name : neighborhood.short_name || "");
            address['full_address'] = `${address['full_address']}, ${address["neighborhood"]}`;
        }

        let sublocality = address_components.find(y => y.types.includes("sublocality") || y.types.includes("sublocality_level_1"));
        if (sublocality) {
            address["sublocality"] = (addressLongName ? sublocality.long_name : sublocality.short_name || "");
            address['full_address'] = `${address['full_address']}, ${address["sublocality"]}`;
        }

        let locality = address_components.find(y => y.types.includes("locality"));
        let administrative_area_level_2 = address_components.find(y => y.types.includes("administrative_area_level_2"));

        if (locality) {
            address["city"] = (addressLongName ? locality.long_name : locality.short_name || "");
            address['full_address'] = `${address['full_address']}, ${address["city"]}`;
        }
        else if (administrative_area_level_2) {
            address["city"] = (addressLongName ? administrative_area_level_2.long_name : administrative_area_level_2.short_name || "");
            address['full_address'] = `${address['full_address']}, ${address["city"]}`;
        }

        let administrative_area_level_1 = address_components.find(y => y.types.includes("administrative_area_level_1"));
        if (administrative_area_level_1) {
            address["state"] = (addressLongName ? administrative_area_level_1.long_name : administrative_area_level_1.short_name || "");
            address["stateIsoCode"] = (administrative_area_level_1.short_name || "");
            address['full_address'] = `${address['full_address']}, ${address["state"]}`;
        }

        let country = address_components.find(y => y.types.includes("country"));
        if (country) {
            address["country"] = (addressLongName ? country.long_name : country.short_name || "");
            address["countryIsoCode"] = (country.short_name || "");
            address['full_address'] = `${address['full_address']}, ${address["country"]}`;
        }

        let postal_code = address_components.find(y => y.types.includes("postal_code"));
        if (postal_code) {
            address["zip"] = (addressLongName ? postal_code.long_name : postal_code.short_name || "");
            address['full_address'] = `${address['full_address']}, ${address["zip"]}`;
        }


    }
    else if (formatted_address.length > 0) {
        let address_arr = formatted_address.split(",")
        address["country"] = address_arr.pop().trim();

        let state_zip = address_arr.pop().trim();
        state_zip = state_zip.split(" ");
        address["zip"] = state_zip.pop().trim();
        address["state"] = state_zip.join(" ").trim();

        address["city"] = address_arr.pop().trim();

        if (address_arr.length > 1) address["street2"] = address_arr.pop().trim();

        address["street1"] = address_arr.join(" ");
    }

    if ((address["address"] || "").trim()) {
        address["address"] = (address.street2 || "");
    }

    return address;
};
